import { useLocale } from 'next-intl';
import * as React from 'react';
import { useRouter } from '@/i18n/routing';

const ToolbarLang: React.FC = () => {
  const locale = useLocale();
  const router = useRouter();

  const changeLang = async (event: any) => {
    router.replace('/', { locale: event.target.value });
  };

  return (
    <div className="group hover:bg-greyscale-separator hover:border-greyscale-separator rounded-md border border-greyscale-label cursor-pointer pr-2">
      <select
        className="font-semibold w-full bg-transparent text-base cursor-pointer px-3 py-2 focus:outline-hidden h-[40px]"
        onChange={changeLang}
        value={locale}
        style={{ WebkitAppearance: 'none' }}
      >
        <option value="it">Italiano</option>
        <option value="en">English</option>
      </select>
    </div>
  );
};

export default ToolbarLang;
