import { Link } from '@/i18n/routing';
import React from 'react';

interface Props {
  label: string;
  renderIcon?: (className: string) => React.ReactElement<any>;
  link: { href: string };
  active?: boolean;
  className?: string;
  closeMenu?: () => void;
}

export default function Item(props: Props) {
  return (
    <li className={`group ${props.className}`}>
      {// @ts-expect-error
      <Link
        {...props.link}
        onClick={props.closeMenu}
        className={`${
          props.active ? 'text-primary' : ''
        } text-base no-underline transition-colors duration-150 text-greyscale-body group-hover:text-primary`}
      >
        <div className="py-2 flex items-center space-x-2 -ml-6">
          <div
            className={`${
              props.active ? 'bg-primary' : ''
            } rounded-r-md self-stretch w-1 group-hover:bg-primary mr-3 transition-colors duration-200`}
          />
          {props.renderIcon &&
            props.renderIcon(
              `${
                props.active ? 'text-primary' : ''
              } text-greyscale-body group-hover:text-primary transition-colors duration-150 w-5 h-5`,
            )}
          <p className="text-base text-greyscale-body flex-1 m-0 h-fit pl-1">{props.label}</p>
        </div>
      </Link>}
    </li>
  );
}
